export enum LocationLevel {
    Outside,
    Downstairs,
    Upstairs,
}
export type Location = {
    room: string;
    cords: number[];
    level: LocationLevel;
};

export type Locations = Location[];

export const mapLocations: Locations = [
    { room: 'Greenhouse', cords: [24.868189806678384, 14.86970977627336], level: LocationLevel.Outside },
    { room: 'LIBRARY', cords: [52.02108963093146, 79.73161083571179], level: LocationLevel.Downstairs },
    { room: 'Cafeteria', cords: [74.69879518072288, 55.74753415566286], level: LocationLevel.Downstairs },
    { room: 'P10', cords: [14.938488576449913, 20.509944518997735], level: LocationLevel.Outside },
    { room: 'P9', cords: [20.913884007029875, 20.63813167224147], level: LocationLevel.Outside },
    { room: 'P8', cords: [26.801405975395433, 20.509944518997735], level: LocationLevel.Outside },
    { room: 'P6', cords: [13.971880492091387, 30.25216816552166], level: LocationLevel.Outside },
    { room: 'P7', cords: [26.537785588752193, 27.94479940713442], level: LocationLevel.Outside },
    { room: 'P3', cords: [17.66256590509666, 33.96959560959], level: LocationLevel.Outside },
    { room: 'P2', cords: [21.79261862917399, 34.09778276283374], level: LocationLevel.Outside },

    { room: 'P1', cords: [26.01054481546573, 34.09778276283374], level: LocationLevel.Outside },
    { room: 'P-1', cords: [26.01054481546573, 34.09778276283374], level: LocationLevel.Outside },

    { room: 'P5', cords: [13.444639718804922, 40.25076611853306], level: LocationLevel.Outside },
    { room: 'B BLDG', cords: [4.745166959578207, 47.94199531315721], level: LocationLevel.Outside },
    { room: 'P11', cords: [5.008787346221441, 59.863400564824644], level: LocationLevel.Outside },
    { room: 'P12', cords: [4.92091388400703, 68.19556552566748], level: LocationLevel.Outside },
    { room: 'JROTC Storage', cords: [5.008787346221441, 71.78480581649208], level: LocationLevel.Outside },
    { room: 'P13', cords: [5.272407732864675, 80.50153223706612], level: LocationLevel.Outside },
    { room: 'JROTC', cords: [4.833040421792618, 84.09077252789072], level: LocationLevel.Outside },
    { room: 'P14', cords: [4.745166959578207, 87.55182566547158], level: LocationLevel.Outside },
    { room: 'P15', cords: [4.833040421792618, 93.83299617441465], level: LocationLevel.Outside },

    { room: '126 POTTERY', cords: [17.486818980667838, 55.633224507781364], level: LocationLevel.Outside },
    { room: '126A', cords: [17.486818980667838, 55.633224507781364], level: LocationLevel.Outside },

    // Room 125
    { room: '125 CHOIR', cords: [19.420035149384884, 53.19766859615038], level: LocationLevel.Outside },
    { room: 'CHOIR', cords: [19.420035149384884, 53.19766859615038], level: LocationLevel.Outside },
    { room: '125', cords: [19.420035149384884, 53.19766859615038], level: LocationLevel.Outside },

    { room: '124 BAND', cords: [27.592267135325134, 54.73591443507521], level: LocationLevel.Outside },
    { room: 'P16', cords: [58.435852372583476, 26.91930218118453], level: LocationLevel.Outside },
    { room: 'P17', cords: [60.017574692442885, 26.91930218118453], level: LocationLevel.Outside },
    { room: '105', cords: [61.95079086115993, 76.78410479299778], level: LocationLevel.Downstairs },
    { room: '122', cords: [37.521968365553604, 43.84000640935766], level: LocationLevel.Outside },

    { room: '123', cords: [37.34622144112478, 46.531936627476114], level: LocationLevel.Outside },
    { room: '122A', cords: [31.72231985940246, 36.66152582770845], level: LocationLevel.Outside },
    { room: '111', cords: [53.075571177504386, 34.48234422256494], level: LocationLevel.Downstairs },
    { room: '110', cords: [55.36028119507909, 34.09778276283374], level: LocationLevel.Downstairs },
    { room: 'P18', cords: [86.02811950790861, 55.76141166102509], level: LocationLevel.Outside },
    { room: 'P19', cords: [86.37961335676626, 64.47813808159914], level: LocationLevel.Outside },
    { room: 'P20', cords: [86.20386643233743, 72.9384901956857], level: LocationLevel.Outside },
    { room: 'P21', cords: [86.29173989455184, 81.52702946301599], level: LocationLevel.Outside },

    { room: '121', cords: [42.70650263620387, 43.9681935626014], level: LocationLevel.Outside },
    { room: '121 LAB', cords: [42.1792618629174, 39.22526889258317], level: LocationLevel.Outside },

    { room: '204A', cords: [92.79437609841827, 30.63672962525287], level: LocationLevel.Upstairs },
    { room: '204B', cords: [94.4639718804921, 33.96959560959], level: LocationLevel.Upstairs },
    { room: 'AUD', cords: [43.67311072056239, 81.52702946301599], level: LocationLevel.Downstairs },
    { room: '215', cords: [74.51669595782073, 30.63672962525287], level: LocationLevel.Upstairs },
    { room: '217', cords: [71.8804920913884, 38.9688945860957], level: LocationLevel.Upstairs },
    { room: '218', cords: [76.97715289982425, 39.09708173933944], level: LocationLevel.Upstairs },
    { room: '219', cords: [82.60105448154658, 39.09708173933944], level: LocationLevel.Upstairs },
    { room: '216', cords: [70.38664323374341, 30.764916778496605], level: LocationLevel.Upstairs },
    { room: '214', cords: [79.52548330404218, 30.508542472009132], level: LocationLevel.Upstairs },
    { room: '213', cords: [85.32513181019333, 25.637430648747173], level: LocationLevel.Upstairs },
    { room: '212', cords: [85.32513181019333, 19.356260139804114], level: LocationLevel.Upstairs },
    { room: '211', cords: [85.32513181019333, 13.459651090592265], level: LocationLevel.Upstairs },
    { room: '210', cords: [85.04016064257029, 5.011785706112548], level: LocationLevel.Upstairs },
    { room: '209', cords: [91.12478031634447, 8.460352114086566], level: LocationLevel.Upstairs },
    { room: '208', cords: [91.12478031634447, 14.613335469785888], level: LocationLevel.Upstairs },
    { room: '207', cords: [91.21265377855887, 20.894505978728944], level: LocationLevel.Upstairs },
    { room: '206', cords: [91.30052724077329, 25.893804955234646], level: LocationLevel.Upstairs },
    { room: '205', cords: [91.30052724077329, 30.89310393174034], level: LocationLevel.Upstairs },
    { room: '203', cords: [97.18804920913884, 39.22526889258317], level: LocationLevel.Upstairs },
    { room: '202', cords: [97.18804920913884, 44.096380715845136], level: LocationLevel.Upstairs },
    { room: '201', cords: [97.18804920913884, 48.96749253910709], level: LocationLevel.Upstairs },
    { room: '220', cords: [88.84007029876977, 39.35345604582691], level: LocationLevel.Upstairs },
    { room: '221', cords: [88.75219683655537, 45.12187794179502], level: LocationLevel.Upstairs },
    { room: '222', cords: [88.84007029876977, 52.5567328299317], level: LocationLevel.Upstairs },
    { room: 'LG GYM', cords: [21.00175746924429, 74.47673603461054], level: LocationLevel.Downstairs },
    { room: 'SM GYM', cords: [14.762741652021088, 77.68141486570393], level: LocationLevel.Downstairs },
    { room: '122B', cords: [35.23725834797891, 34.48234422256494], level: LocationLevel.Outside },
    { room: '120', cords: [48.50615114235501, 47.42924670018226], level: LocationLevel.Outside },
    { room: '111A', cords: [48.06678383128295, 37.43064874717087], level: LocationLevel.Downstairs },
    { room: '112', cords: [53.339191564147626, 43.455444949626454], level: LocationLevel.Downstairs },
    { room: '113', cords: [53.339191564147626, 50.89029983776313], level: LocationLevel.Downstairs },
    { room: '109', cords: [56.94200351493849, 43.71181925611393], level: LocationLevel.Downstairs },
    { room: '108', cords: [56.76625659050967, 54.992288741562675], level: LocationLevel.Downstairs },
    { room: '107', cords: [56.67838312829525, 59.863400564824644], level: LocationLevel.Downstairs },
    { room: '106', cords: [62.65377855887522, 60.11977487131212], level: LocationLevel.Downstairs },
    { room: '114', cords: [49.209138840070295, 59.99158771806839], level: LocationLevel.Downstairs },
    { room: '115', cords: [44.288224956063274, 59.99158771806839], level: LocationLevel.Downstairs },
    { room: '116', cords: [39.36731107205624, 59.99158771806839], level: LocationLevel.Downstairs },
    { room: '117', cords: [40.070298769771526, 66.01638392052396], level: LocationLevel.Downstairs },
    { room: '118', cords: [45.782073813708266, 65.50363530754902], level: LocationLevel.Downstairs },
    { room: '119', cords: [50.96660808435852, 65.63182246079276], level: LocationLevel.Downstairs },
    { room: '100', cords: [56.15114235500879, 86.5263284395217], level: LocationLevel.Downstairs },
    { room: '101', cords: [51.14235500878734, 86.39814128627796], level: LocationLevel.Downstairs },
    { room: '102', cords: [46.1335676625659, 86.5263284395217], level: LocationLevel.Downstairs },
    { room: '103', cords: [41.03690685413005, 86.14176697979049], level: LocationLevel.Downstairs },
    { room: 'WGHT ROOM', cords: [36.81898066783831, 86.65451559276543], level: LocationLevel.Downstairs },
    { room: 'COUNS', cords: [71.35325131810193, 69.7338113645923], level: LocationLevel.Downstairs },
];
